<template>
  <header ref="header" class="border-static-default-low relative border-b-1">
    <TopHeader />
    <div
      class="bg-static-default-low flex items-center justify-between gap-32 px-32"
    >
      <TheLogo class="shrink-0" />

      <div class="overflow-x-auto">
        <NavMenu :headerHeight />
      </div>

      <div class="">
        <TradeInButton />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useElementSize } from '@vueuse/core'

import NavMenu from './NavMenu/NavMenu.vue'
import TheLogo from './TheLogo.vue'
import TopHeader from './TopHeader.vue'
import TradeInButton from './TradeInButton.vue'

const header = ref<HTMLElement>()
const { height: headerHeight } = useElementSize(header)
</script>
