<template>
  <div>
    <div class="flex gap-24">
      <RevLink
        v-for="{ label, name, url, icon, classNames } in links"
        :key="name"
        class="flex items-center gap-4"
        data-test="footer-link"
        :to="url"
        :tracking="{ name: name, zone: 'header' }"
        :underlined="false"
      >
        <component :is="icon" v-if="icon" :class="classNames" />
        <span class="body-2-bold" :class="classNames">
          {{ label }}
        </span>
      </RevLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { RevLink } from '@ds/components/Link'

import { useNavLinks } from '../composables/useNavLinks'

const { links } = useNavLinks()
</script>
