<template>
  <CountrySwitchRedirectionBanner
    v-if="detectedCountryCode && linkToDetectedMarket && isRedirectionSuggested"
    ref="bannerRef"
    :currentCountryCode="currentMarketCountryCode"
    data-test="country-switch-redirection-banner"
    :detectedCountryCode
    :handleClose
    :handleRedirection
    :linkToDetectedMarket
  />
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue'

import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'

import { useCountrySwitchRedirection } from '~/scopes/navigation/composables/useCountrySwitchRedirection'

import CountrySwitchRedirectionBanner from './CountrySwitchRedirectionBanner.vue'

const userStore = useUserStore()
const detectedCountryCode = computed(() => userStore.user.detectedCountryCode)
const {
  isRedirectionSuggested,
  handleClose,
  handleRedirection,
  currentMarketCountryCode,
  linkToDetectedMarket,
} = useCountrySwitchRedirection(toRef(detectedCountryCode))
</script>
