<template>
  <RevLink
    class="bg-action-default-brand-mid flex gap-4 border-0 px-12 py-8 hover:bg-action-default-brand-low hover:no-underline"
    :to="`/${locale}/buyback/home`"
    :underlined="false"
  >
    <IconSwap class="text-static-brand-mid" />
    <span class="text-static-brand-mid">
      {{ i18n(translations.tradeIn) }}
    </span>
  </RevLink>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { RevLink } from '@ds/components/Link'
import { IconSwap } from '@ds/icons/IconSwap'

import translations from './TheHeader.translations'

const locale = useI18nLocale()
const i18n = useI18n()
</script>
