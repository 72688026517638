export default {
  allOurProductsNew: {
    id: 'header_all_our_products',
    defaultMessage: 'All our products',
  },
  aboutUs: {
    id: 'header_to_about_us',
    defaultMessage: 'About us',
  },
  sellYourTech: {
    id: 'header_to_buyback',
    defaultMessage: 'Sell your tech',
  },
  help: {
    id: 'header_to_help',
    defaultMessage: 'Help',
  },
  verifiedRefurbished: {
    id: 'header_to_verified_refurbished',
    defaultMessage: 'Verified Refurbished',
  },
  backStories: {
    id: 'header_to_back_stories',
    defaultMessage: 'Back Stories',
  },
  back: {
    id: 'mweb_nav_back',
    defaultMessage: 'Back',
  },
  search: {
    id: 'mweb_nav_search',
    defaultMessage: 'Search',
  },
  cartIconAltText: {
    id: 'header_cart_icon_alt_text',
    defaultMessage: 'Cart',
  },
  userIconAltText: {
    id: 'header_user_submenu_icon_alt_text',
    defaultMessage: 'User sub menu',
  },
  myAccount: {
    id: 'header_user_submenu_my_account',
    defaultMessage: 'My account',
  },
  myOrders: {
    id: 'header_user_submenu_my_orders',
    defaultMessage: 'My orders',
  },
  myFavorites: {
    id: 'header_user_submenu_my_favorites',
    defaultMessage: 'My favorites',
  },
  mySales: {
    id: 'header_user_submenu_my_sales',
    defaultMessage: 'My sales',
  },
  logOut: {
    id: 'header_user_submenu_log_out',
    defaultMessage: 'Log out',
  },
  showAllDepartments: {
    id: 'show_all_departments',
    defaultMessage: 'Show all departments',
  },
  shop: {
    id: 'mweb_nav_to_shop',
    defaultMessage: 'Shop',
  },
  goodDeals: {
    id: 'menu_good_deals',
    defaultMessage: 'Good deals',
  },
  holidays: {
    id: 'menu_holidays',
    defaultMessage: 'Holidays',
  },
  categories: {
    id: 'menu_categories',
    defaultMessage: 'Categories',
  },
  see_all: {
    id: 'menu_see_all',
    defaultMessage: 'See all',
  },
  goodToKnow: {
    id: 'good_to_know',
    defaultMessage: 'Good to know',
  },
  tradeIn: {
    id: 'menu_trade-in',
    defaultMessage: 'Trade-in',
  },
}
