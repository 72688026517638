<template>
  <div class="mt-32 flex h-full flex-col justify-between">
    <RevIllustration
      alt=""
      class="mx-auto mb-24 mt-0 block w-64 md:w-80"
      :height="80"
      loading="eager"
      :src="cookieImage"
      :width="81"
    />
    <div class="mb-auto max-h-full">
      <p class="body-2 mb-16 text-center">
        {{ i18n(translations.paragraph) }}
      </p>
      <p class="body-2 mb-16 text-center">
        <FormattedMessage :definition="translations.cookiesUsage">
          <template #cookiesLink>
            <RevLink
              class="body-2-bold"
              target="_blank"
              :to="LEGAL_PAGES.COOKIES"
              :underline="true"
              @click="handleClickCookiesTerms"
            >
              {{ i18n(translations.cookies) }}
            </RevLink>
          </template>
          <template #legalMentionsLink>
            <RevLink
              class="body-2-bold"
              target="_blank"
              :to="LEGAL_PAGES.LEGAL_MENTIONS"
              :underline="true"
              @click="handleClickLegalMentions"
            >
              {{ i18n(translations.legalMentions) }}
            </RevLink>
          </template>
          <template #dataProtectionLink>
            <RevLink
              class="body-2-bold"
              target="_blank"
              :to="LEGAL_PAGES.DATA_PROTECTION"
              :underline="true"
              @click="handleClickDataProtection"
            >
              {{ i18n(translations.dataProtection) }}
            </RevLink>
          </template>
        </FormattedMessage>
      </p>
    </div>
    <div class="flex flex-col px-0 py-16 text-center">
      <RevLink
        :class="[
          'body-2-bold mb-24',
          {
            'text-onaction-default-hi-disabled pointer-events-none cursor-not-allowed':
              loading,
          },
        ]"
        @click="handleClickRefuse"
      >
        {{ i18n(translations.buttonRefuse) }}
      </RevLink>
      <RevButton
        class="mb-12"
        :disabled="loading"
        variant="secondary"
        @click="handleClickParams"
      >
        {{ i18n(translations.buttonSettings) }}
      </RevButton>
      <RevButton
        data-qa="accept-cta"
        :disabled="loading"
        :loading="accepted && loading"
        variant="primary"
        @click="handleClickAccept"
      >
        {{ i18n(translations.buttonAccept) }}
      </RevButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import translations from './CookiesModal.translations'
import {
  COOKIES_TRACKING_NAME,
  COOKIES_TRACKING_PREFIX,
  LEGAL_PAGES,
} from './constants'

const i18n = useI18n()
const { trackClick } = useTracking()

type FirstStepProps = {
  zone?: string
  loading?: boolean
}
type FirstStepEmit = {
  accept: []
  refuse: []
  params: []
}
const props = withDefaults(defineProps<FirstStepProps>(), {
  zone: '',
  loading: false,
})
const emit = defineEmits<FirstStepEmit>()

const accepted = ref(false)
const cookieImage = '/img/legal/cookies.png'

const trackingZone = computed(() => `${COOKIES_TRACKING_PREFIX}_${props.zone}`)

function handleClickAccept() {
  accepted.value = true
  trackClick({ zone: trackingZone.value, name: COOKIES_TRACKING_NAME.ACCEPT })
  emit('accept')
}
function handleClickRefuse() {
  if (!props.loading) {
    trackClick({ zone: trackingZone.value, name: COOKIES_TRACKING_NAME.REFUSE })
    emit('refuse')
  }
}
function handleClickParams() {
  trackClick({ zone: trackingZone.value, name: COOKIES_TRACKING_NAME.SETTINGS })
  emit('params')
}
function handleClickCookiesTerms() {
  trackClick({ zone: trackingZone.value, name: COOKIES_TRACKING_NAME.INFO })
}
function handleClickLegalMentions() {
  trackClick({
    zone: trackingZone.value,
    name: COOKIES_TRACKING_NAME.LEGAL_MENTIONS,
  })
}
function handleClickDataProtection() {
  trackClick({
    zone: trackingZone.value,
    name: COOKIES_TRACKING_NAME.DATA_PROTECTION,
  })
}
</script>
