<template>
  <div
    ref="bannerRef"
    class="border-static-default-low flex w-full items-center justify-center border-b-1 pl-24 pr-8"
    data-test="country-switch-redirection-banner"
  >
    <div class="group flex flex-1 items-center justify-center gap-x-10 py-16">
      <p class="text-static-default-hi body-2-bold text-left md:text-left">
        {{
          i18n(translations.redirectionBannerLabel, {
            marketPlace: i18n.country(detectedCountryCode),
          })
        }}
      </p>
      <RevButton
        class="mr-8"
        size="small"
        variant="primary"
        @click="handleRedirectionClick"
        >{{ i18n(translations.redirectionBannerUpdateButton) }}</RevButton
      >
    </div>
    <RevButtonRounded
      aria-label="close"
      :icon="IconCross"
      variant="secondary"
      @click="handleClose"
    />
  </div>
</template>

<script setup lang="ts">
import { onUnmounted, ref } from 'vue'

import { type CountryCodeValue } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { IconCross } from '@ds/icons/IconCross'
import { useIntersectionObserver } from '@vueuse/core'

import translations from './CountrySwitch.translations'

const props = defineProps<{
  detectedCountryCode: string
  currentCountryCode: CountryCodeValue
  linkToDetectedMarket: string
  handleClose: () => void
  handleRedirection: () => void
}>()

const i18n = useI18n()
const bannerRef = ref<HTMLElement | null>()

const { trackCountrySwitchClick, trackCountrySwitchBannerImpression } =
  useTracking()

const trackCountryRedirection = () => {
  trackCountrySwitchClick({
    name: 'prompt_country_switch',
    zone: 'banner_country_switch',
  })
}

const handleRedirectionClick = () => {
  trackCountryRedirection()
  props.handleRedirection()
}

function trackImpression() {
  trackCountrySwitchBannerImpression({
    name: 'prompt_country_switch',
    zone: 'banner_country_switch',
  })
}

const { stop } = useIntersectionObserver(bannerRef, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    trackImpression()
  }
})

onUnmounted(() => {
  stop()
})
</script>
